import React, { useContext, useEffect, useState } from "react";
import { Box, Chip, Divider, Rating, Tooltip, Avatar } from "@mui/material";
import { useParams } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Warning } from "@mui/icons-material";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import ResponseModal from "../../../../../global/ResponseModal";
import { ENTITY_TYPE_PROVIDER } from "../../../../../../globalConstants";
import ApiRequestErrorHandler from "../../../../../global/ApiRequestErrorHandler";
import { ReadonlyInput } from "../../../../../global/Input/Readonly";
import { createDisplayObj } from "../../../../../global/helpers";
import ProviderListView from "../../../../../global/ProviderDetailModal/ProviderToProviderRelation/ProviderListView";
import { provider } from "../../../../../api/endpoints/provider";
import PermissionWrapper from "../../../../../global/PermissionWrapper";
import PropTypes from "prop-types";
import { PDStateContext } from "../context/ProviderDetailProvider";

LeftSideBar.propTypes = {
  providerInformation: PropTypes.object.isRequired,
};

export default function LeftSideBar({ providerInformation }) {
  const { userRoles, loggedInUser, accessToken } = useContext(DataContext);
  const { trigger } = useContext(PDStateContext);
  const { id: providerId } = useParams();

  //State management
  const [parentTrigger, setParentTrigger] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [operationHours, setOperationHours] = useState([]);
  const [defaultWeek, setDefaultWeek] = useState([]);
  const [providerEmails, setProviderEmails] = useState([]);
  const [typeDisplayObj, setTypeDisplayObj] = useState("");
  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const [pickedOptions, setPickedOptions] = useState([]);
  const [providers, setProviders] = useState([]);
  const [rating, setRating] = useState();

  useEffect(() => {
    if (Array.isArray(providerInformation?.provider_types)) {
      setPickedOptions(providerInformation?.provider_types);
    }
  }, [providerInformation?.provider_types]);

  useEffect(() => {
    if (providerInformation?.rating) {
      setRating(providerInformation?.rating);
    }
  }, [providerInformation?.rating]);

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setParentTrigger(!parentTrigger);
  };

  const handleError = (error) => {
    console.error(error);
  };

  // Fetch provider office hours
  useEffect(() => {
    if (userRoles.permissions.includes("provider.view_providerofficehours")) {
      const fetchOfficeHours = async () => {
        try {
          const response = await axios.get(
            `/api/provider_office_hours/?ordering=day,start_time&provider=${providerId}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          );
          setOperationHours(response.data.results);
        } catch (error) {
          handleError(error);
        }
      };

      fetchOfficeHours();
    }
  }, [accessToken, providerId, userRoles.permissions, trigger]);

  // Fetch week options and email types
  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_fieldoptions")) {
      const fetchFieldOptions = async () => {
        try {
          const weekOptionsResponse = await axios.get(
            `/api/field_options/?content_type=providerofficehours&field=day&ordering=order`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          );
          setDefaultWeek(weekOptionsResponse.data.results);

          const emailTypeResponse = await axios.get(
            `/api/field_options/?content_type=provideremail&field_name=email_type`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          );
          setTypeDisplayObj(
            createDisplayObj(emailTypeResponse.data.results, "email_type")
          );
        } catch (error) {
          handleError(error);
        }
      };

      fetchFieldOptions();
    }
  }, [accessToken, userRoles.permissions]);

  // Fetch relationship providers
  useEffect(() => {
    const fetchProviderRelations = async () => {
      try {
        const response = await provider.getProviderRelations(
          providerId,
          accessToken,
          null
        );
        setProviders(response.results);
      } catch (error) {
        handleError(error);
      }
    };
    if (userRoles.permissions.includes("provider.view_providerrelation")) {
      fetchProviderRelations();
    }
  }, [accessToken, providerId, userRoles.permissions]);

  // Fetch provider emails
  useEffect(() => {
    if (userRoles.permissions.includes("provider.view_provideremail")) {
      const fetchProviderEmails = async () => {
        try {
          const response = await axios.get(
            `/api/provider_email/?provider=${providerId}&ordering=created_at`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          );
          setProviderEmails(response.data.results);
        } catch (error) {
          handleError(error);
        }
      };

      fetchProviderEmails();
    }
  }, [accessToken, providerId, userRoles.permissions, trigger]);

  const handleRatingChange = async (event, val) => {
    try {
      await axios.patch(
        `/api/provider/${event.target.name}/`,
        { rating: val },
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      );
      setRating(val);
      setResponseModal(true);
      setResponseBreakdown("Provider Rating Updated");
      setIsError(false);
    } catch (error) {
      const errArr = ApiRequestErrorHandler(error.response);
      setIsError(true);
      setResponseModal(true);
      setResponseBreakdown(errArr);
    }
  };

  const fields = [
    { label: "Name", value: providerInformation?.name },
    {
      label: "Address",
      value:
        providerInformation?.address?.formatted ||
        providerInformation?.address?.raw,
    },
    { label: "Phone", value: providerInformation?.phone },
    { label: "Main Email", value: providerInformation?.email },
  ];

  return (
    <>
      <div className="flex my-5">
        <div className="w-4/12 flex justify-center">
          <button className="relative w-[125px] h-[125px]">
            <Avatar className="w-full h-full text-black bg-black" alt="">
              <AccountCircleIcon className="w-[155px] h-[155px] bg-white" />
            </Avatar>
          </button>
        </div>
        <div className="my-2 w-8/12">
          <p className="text-center text-3xl mb-5">
            {providerInformation?.name}
          </p>

          <div className="grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-2 text-sm">
            <div className="flex flex-col items-end justify-center">
              <p
                className={`text-xs ${
                  providerInformation?.in_network
                    ? "bg-green-600"
                    : "bg-red-600"
                } text-white rounded-md w-fit px-2`}
              >
                {providerInformation?.in_network
                  ? "In-Network"
                  : "Out-of-Network"}
              </p>
            </div>
            <div className="flex flex-col items-start gap-2 w-fit">
              <PermissionWrapper
                permission={
                  !isProvider &&
                  userRoles.permissions.includes("provider.view_provider")
                }
              >
                <div className="flex justify-center items-center gap-1">
                  <p>Rating</p>
                  <Rating
                    name={`${providerInformation.pk}`}
                    value={+rating}
                    className="flex justify-center text-base"
                    disabled={
                      !userRoles.permissions.includes(
                        "provider.change_provider"
                      )
                    }
                    onChange={(event, newValue) => {
                      handleRatingChange(event, newValue);
                    }}
                  />
                  {!rating && (
                    <Tooltip title="Not yet rated">
                      <Warning style={{ color: "red" }} className="text-sm" />
                    </Tooltip>
                  )}
                </div>
              </PermissionWrapper>
            </div>
            <p className="text-right">Parent Entity</p>
            <p>{""}</p>
          </div>
        </div>
      </div>
      <Divider />
      <div className="my-2">
        <h2 className="text-xl font-bold">Location Info</h2>
        <div className="grid grid-cols-2 gap-x-6 gap-y-2 mt-2">
          <div className="gap-2 col-span-1 flex flex-col items-center">
            {fields.map((field) => (
              <div key={field.label} className="flex items-center gap-1 w-full">
                <p className="w-1/2">{field.label}</p>
                <ReadonlyInput value={field.value} className="w-1/2" />
              </div>
            ))}
            <PermissionWrapper
              permission={userRoles.permissions.includes(
                "provider.view_provideremail"
              )}
            >
              {Object.keys(typeDisplayObj).map((key) => {
                const email = providerEmails.find(
                  (email) => email.email_type === parseInt(key, 10)
                );

                return (
                  <div key={key} className="flex items-center gap-1 w-full">
                    <p className="w-1/2">
                      {typeDisplayObj[key] === "Updates/Appointments"
                        ? "Appointment"
                        : typeDisplayObj[key]}
                    </p>
                    <ReadonlyInput
                      value={email ? email.email : null}
                      className="w-1/2"
                    />
                  </div>
                );
              })}
            </PermissionWrapper>
          </div>
          <PermissionWrapper
            permission={userRoles.permissions.includes(
              "provider.view_providerofficehours"
            )}
          >
            <div className="gap-2 col-span-1 flex flex-col">
              {defaultWeek.map((item) => {
                const hoursForDay = operationHours.filter(
                  (hour) => hour.day === item.key
                ); //Create hours for each day

                return (
                  <div key={item.key} className="grid grid-cols-4">
                    <p className="col-span-1 whitespace-normal break-words overflow-hidden">
                      {item.label.substring(0, 3)}:
                    </p>
                    <div className="col-span-3 flex flex-col">
                      {hoursForDay.length > 0 ? (
                        hoursForDay.slice(-2).map((hourItem) => (
                          <p key={hourItem.id}>
                            {new Date(
                              `2024-06-25T${hourItem.start_time}`
                            ).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            -{" "}
                            {new Date(
                              `2024-06-25T${hourItem.end_time}`
                            ).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        ))
                      ) : (
                        <div>Closed</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <Divider />
      <div className="my-2">
        <h2 className="text-xl font-bold">Provider Type</h2>
        <PermissionWrapper
          permission={userRoles.permissions.includes("provider.view_provider")}
        >
          {pickedOptions.length !== 0 && (
            <Box display="flex" gap={2} marginLeft={2} flexWrap={"wrap"} mt={2}>
              {pickedOptions.map((opt, index) => (
                <Chip
                  key={`${opt.id || opt}-${index}`}
                  label={opt.name || opt}
                  variant="outlined"
                  sx={{
                    fontSize: "14px",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    color: "white",
                    borderColor: "#5248E6",
                    backgroundColor: "#5248E6",
                  }}
                />
              ))}
            </Box>
          )}
        </PermissionWrapper>
      </div>
      <Divider />
      <div className="my-2">
        <h2 className="text-xl font-bold">Relationships</h2>
        <PermissionWrapper
          permission={userRoles.permissions.includes(
            "provider.view_providerrelation"
          )}
        >
          <div className="mt-2 flex flex-col gap-1">
            <ProviderListView providerList={providers || []} />
          </div>
        </PermissionWrapper>
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </>
  );
}
