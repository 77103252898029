import React from "react";
import { Route } from "react-router-dom";
import App from "../components/cm/App";
import ClientListSearchv3 from "../components/cm/Dashboard/FullPages/ClientSearchFullv3";
import CalendarFull from "../components/cm/Dashboard/FullPages/CalendarFull";
import NonNetworkProvForm from "../components/cm/Dashboard/FullPages/NonNetProvForm";
import AssignAppt from "../components/cm/Dashboard/FullPages/AssignAppt/AssignAppt";
import FollowUpCalendar from "../components/cm/Dashboard/FullPages/ClientDetail/FollowUps/Calendar";
import CaseManagerContact from "../components/cm/Dashboard/FullPages/CaseManagerContact";
import ClientDetail from "../components/cm/Dashboard/FullPages/ClientDetail";
import AddClientForm from "../components/cm/Dashboard/FullPages/ClientForm/AddClientForm";
import SearchByMissedAppts from "../components/cm/Dashboard/FullPages/SearchByMissedAppts";
import AnalyticsFullV2 from "../components/cm/Dashboard/FullPages/AnalyticsFullV2";
import CreateLawfirm from "../components/cm/Dashboard/FullPages/CreateLawfirm";
import ProviderClientSearch from "../components/cm/Dashboard/FullPages/ProviderClientSearch";
import NeedsSupervisor from "../components/cm/Dashboard/FullPages/NeedsSupervisor";
import OverarchingProvMap from "../components/cm/Dashboard/FullPages/OverarchingProvMap";
import InNetworkProviders from "../components/cm/Dashboard/FullPages/InNetworkProviders";
import ActionItems from "../components/cm/Dashboard/FullPages/ActionItems";
import ActionItemCounts from "../components/cm/Dashboard/FullPages/ActionItems/ActionItemCounts";
import Settlement from "../components/cm/Dashboard/FullPages/Settlement";
import GlobalUserDash from "../components/cm/Dashboard/DashboardVersions/GlobalUserDash";
import LiabilityRequestedList from "../components/cm/Dashboard/FullPages/LiabilityRequestedList";
import LawfirmPropertyDamage from "../components/cm/Dashboard/FullPages/LawfirmPropertyDamage";
import LawfirmPolicyLimit from "../components/cm/Dashboard/FullPages/LawfirmPolicyLimit";
import LawfirmSortLiability from "../components/cm/Dashboard/FullPages/LawfirmSortByLiability";
import LawfirmSortByReport from "../components/cm/Dashboard/FullPages/LawfirmSortByReport";
import DocumentSearch from "../components/cm/Dashboard/FullPages/DocumentSearch";
import ProviderMap from "../components/cm/Dashboard/FullPages/ProviderMap";
import MyAccount from "../components/cm/Dashboard/FullPages/MyAccount";
import UserProfile from "../components/cm/Dashboard/FullPages/UserManager/UserProfile";
import UserProfileManager from "../components/cm/Dashboard/FullPages/UserManager/UserProfileManager";
import NeedsAppointment from "../components/cm/Dashboard/FullPages/NeedsAppointment";
import AddUserStandalone from "../components/cm/Dashboard/FullPages/UserManager/UserProfileManager/AddUserStandalone";
import LogPhone from "../components/cm/Dashboard/FullPages/LogPhone";
import LogSMS from "../components/cm/Dashboard/FullPages/LogSMS";
import AccountabilityLog from "../components/cm/Dashboard/FullPages/AccountabilityLog";
import CompletedNoBill from "../components/cm/Dashboard/FullPages/CompletedNoBill";
import Reports from "../components/cm/Dashboard/FullPages/Reports";
import ReportDetail from "../components/cm/Dashboard/FullPages/Reports/components/ReportsDetail/ReportDetail";
import ProviderDetail from "../components/cm/Dashboard/FullPages/ProviderDetail";
import ProviderManager from "../components/cm/Dashboard/FullPages/ProviderManager";

const globalRoutes = () => (
  <Route path={`/`} element={<App />}>
    <Route path="dashboard" element={<GlobalUserDash />} />
    <Route path="search" element={<ClientListSearchv3 />} />
    <Route path="provider-map" element={<ProviderMap />} />
    <Route path="appointment-calendar" element={<CalendarFull />} />
    <Route path="provider/:id" element={<ProviderDetail />} />
    <Route path="create-provider" element={<NonNetworkProvForm />} />
    <Route path="addappt" element={<AssignAppt />} />
    <Route path="callback-calendar" element={<FollowUpCalendar/>} />
    <Route path="contact" element={<CaseManagerContact />} />
    <Route path="client-detail/:id" element={<ClientDetail />} />
    <Route path="my-account" element={<MyAccount />} />
    <Route path="profile/:id" element={<UserProfile />} />
    <Route path="add-client" element={<AddClientForm />} />
    <Route path="add-application-user" element={<AddUserStandalone />} />
    <Route path="missed-appts" element={<SearchByMissedAppts />} />
    <Route path="needs-appointment" element={<NeedsAppointment />} />
    <Route path="analytics" element={<AnalyticsFullV2 />} />
    <Route path="create-law-firm" element={<CreateLawfirm />} />
    <Route path="provider-client-search" element={<ProviderClientSearch />} />
    <Route path="needs-supervisor" element={<NeedsSupervisor />} />
    <Route path="globalmap" element={<OverarchingProvMap />} />
    <Route path="in-network-providers" element={<InNetworkProviders />} />
    <Route path="action-items" element={<ActionItems />} />
    <Route path="action-item-counts" element={<ActionItemCounts />} />
    <Route path="settlement/:id" element={<Settlement />} />
    <Route path="liability-requested" element={<LiabilityRequestedList />} />
    <Route path="needs-property-damage" element={<LawfirmPropertyDamage />} />
    <Route path="needs-policy-limit" element={<LawfirmPolicyLimit />} />
    <Route path="liability-sort" element={<LawfirmSortLiability />} />
    <Route path="needs-police-report" element={<LawfirmSortByReport />} />
    <Route path="document-search" element={<DocumentSearch />} />
    <Route path="user-profiles" element={<UserProfileManager />} />
    <Route path="phone-log" element={<LogPhone />} />
    <Route path="sms-log" element={<LogSMS />} />
    <Route path="action-log" element={<AccountabilityLog />} />
    <Route path="completed-appointment-with-no-bill" element={<CompletedNoBill />} />
    <Route path="followup-calendar" element={<FollowUpCalendar />} />
    <Route path="reports">
      <Route index={true} element={<Reports />} />
      <Route path=":id" element={<ReportDetail />} />
    </Route>
    <Route path="providers" element={<ProviderManager />} />
  </Route>
);

export default globalRoutes;
