import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import LeftSideBar from "./LeftSidebar";
import ProviderOptions from "./ProviderOptions";
import {
  PDDispatchContext,
  PDStateContext,
} from "./context/ProviderDetailProvider";
import { setProvider } from "./state/actions";
import axios from "./../../../../api/axios";
import DataContext from "../../../../../context/DataContext";

const ProviderDetailContent = () => {
  const { id: providerId } = useParams();

  const { accessToken, userRoles } = useContext(DataContext);
  const { provider, trigger } = useContext(PDStateContext);
  const dispatch = useContext(PDDispatchContext);

  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const fetchProviderInformation = async () => {
      try {
        const response = await axios.get(
          `/api/provider/${providerId}?many-provider-types=true`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        );
        setProvider(dispatch, response.data);
      } catch (error) {
        console.error(error);
        setFetchError(true);
      }
    };
    if (
      userRoles.permissions.includes("provider.change_provider") &&
      userRoles.permissions.includes("provider.view_provider")
    ) {
      fetchProviderInformation();
    }
  }, [accessToken, dispatch, providerId, userRoles.permissions, trigger]);

  if (
    fetchError ||
    !(
      userRoles.permissions.includes("provider.change_provider") &&
      userRoles.permissions.includes("provider.view_provider")
    )
  ) {
    return (
      <div className="w-full min-h-screen">
        <p>There was an error loading this content</p>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col xl:flex-row">
      <div className="self-center bg-white shadow-lg p-4 w-full xl:w-4/12 xl:mr-6 xl:h-full overflow-y-auto">
        {!!provider && <LeftSideBar providerInformation={provider}/>}
      </div>
      <div className="h-full flex flex-col flex-1 xl:w-8/12 xl:mt-0 mt-4">
        <ProviderOptions />
      </div>
    </div>
  );
};

export default ProviderDetailContent;
